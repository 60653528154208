// Override default variables before the import
$body-bg: #ffffff !default;
$body-color: #111433 !default;

$primary: #293b80 !default;
$info: #2196f3 !default;
$danger: #ff0000 !default;
$dark: $body-bg !default;

// Import Bootstrap and its default variables

@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
.ff-cookie {
  font-family: "Cookie";
  line-height: initial;
}

@import "~bootstrap/scss/bootstrap.scss";
